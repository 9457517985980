import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import logo from '../../images/bobs-business-logo.png'
import phoneImage from '../../images/phone-midnight.png'
import theme from '../styling/theme'
import MainGrid from '../styling/MainGrid'
import { appendUrlPartnerTracking } from '../../util/url'
import Button from './Button/Button'
import { LinkProps } from '@material-ui/core'
import TrustBox from '../Header/Trustpilot'

interface NavigationProps {
  demoLink: string
  guide?: string
}

const NavigationLanding: FunctionComponent<NavigationProps> = ({
  demoLink,
  guide,
}) => {
  const [showStickyNav, setShowStickyNav] = useState(false)
  const [scrollPostion, setScrollPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    scrollPostion >= 1500 ? setShowStickyNav(true) : setShowStickyNav(false)
  }, [scrollPostion])

  return (
    <Container>
      <TopNav>
        <MainGrid>
          <TopNavInner>
            {/* <Link to={appendUrlPartnerTracking('/')}> */}
            <figure className="image">
              <img
                src={logo}
                alt="Bob's Business Logo"
                style={{ height: '50px', width: 'auto' }}
              />
            </figure>
            {/* </Link> */}
            <PhoneNum>
              <img src={phoneImage} alt="Call us on:" style={{ height: '16px', paddingRight: '5px' }} />
              <a href="tel:03300583009">0330 058 3009</a>
            </PhoneNum>
          </TopNavInner>
        </MainGrid>
      </TopNav>

      {showStickyNav ? (
        <StickyBottomNab>
          <MainGrid>
            <TopNavInner>
              <TrustBox />
              <PhoneNum>
                <img src={phoneImage} alt="Call us on:" style={{ height: '16px', paddingRight: '5px' }} />
                <a href="tel:03300583009">0330 058 3009</a>
              </PhoneNum>
              <TopLinks>
                {guide != null || guide != undefined ? (
                  <Button
                    secondary
                    href={guide}
                    width={'250px'}
                    maxWidth="unset"
                  >
                    Download cybersecurity guide
                  </Button>
                ) : null}
                {demoLink != null || demoLink != undefined ? (
                  <Button href={demoLink} width={'150px'}>
                    Get a demo
                  </Button>
                ) : null}
              </TopLinks>
            </TopNavInner>
          </MainGrid>
        </StickyBottomNab>
      ) : null}
    </Container>
  )
}

export default NavigationLanding

const Container = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  // padding: 0 20px;
  @media screen and (min-width: 1024px) {
    padding: 0;
  }
`

const PhoneNum = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  font-size: 22px;
  font-weight: bold;
  color: #1D253C;
`


const TopNav = styled.div`
  border-bottom: 1px solid ${theme.grey.keyline};
  @media (max-width: 1024px) {
  }
`
const TopNavInner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.3rem 0 1.3rem;
`

const TopLinks = styled.div`
  display: flex;
  align-items: center;
  min-width: 150px;

  & a:not(:last-of-type) {
    margin-right: 2.2rem;
    font-weight: bold;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`
const StickyBottomNab = styled.div`
  border-top: 1px solid lightgrey;
  background: white;
  position: fixed;
  width: 100vw;
  bottom: 0;
  z-index: 70;
  border-bottom: 1px solid ${theme.grey.keyline};
  @media (max-width: 1024px) {
  }
`
