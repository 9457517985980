import React from 'react'
import styled from 'styled-components'
import Button from './shared/Button/Button'
import LargeGrid from './styling/LargeGrid'
import MainGrid from './styling/MainGrid'
import theme from './styling/theme'

interface PromoProps {
  demoLink: string
  promoData: {
    promoBannerTitle: string
    promoBannerListItemOne: string
    promoBannerListItemTwo: string
    promoBannerListItemThree: string
  }
}

const LandingPagePromotionBanner = ({ demoLink, promoData }: PromoProps) => {
  return (
    <LargeGrid>
      <BannerContainer>
        <MainGrid>
          <Grid>
            <Header>{promoData.promoBannerTitle}</Header>
            <ListContainer className="promoBannerList">
              <li>{promoData.promoBannerListItemOne}</li>
              <li>{promoData.promoBannerListItemTwo}</li>
              <li>{promoData.promoBannerListItemThree}</li>
            </ListContainer>
            <ButtonContainer>
              <Button href={demoLink}>Get a demo</Button>
            </ButtonContainer>
          </Grid>
        </MainGrid>
      </BannerContainer>
    </LargeGrid>
  )
}

export default LandingPagePromotionBanner

const BannerContainer = styled.div`
  background: ${theme.text.main};
  color: white;
  margin-top: 30px;
`

const Grid = styled.div`
  display: grid;
  grid-template-rows: repeat(2 1fr);
  padding: 30px 0;
  @media (min-width: 1200px) {
    padding: 60px 0;
    grid-template-rows: 2;
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;
    align-items: center;
  }
`

const Header = styled.h2`
  color: white;
  @media (min-width: 1200px) {
    grid-column: 1/ 5;
  }
`

const ListContainer = styled.ul`
  @media (min-width: 1200px) {
    grid-column: 6/13;
  }
  & li {
    color: ${theme.text.white};
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
const ButtonContainer = styled.div`
  padding-top: 20px;
  @media (min-width: 1200px) {
    grid-column: 6/8;
  }
`
