import React, { FunctionComponent, useEffect } from 'react'
import Helmet from 'react-helmet'
import NavigationLanding from './shared/NavigationLanding'
import Footer from './shared/IsolatedFooter/IsolatedFooter'
import './all.sass'
import './styling/layout.scss'
import Theme from './StyledComponents/Theme'
import GlobalStyles from './StyledComponents/GlobalStyles'

import LandingPagePromotionBanner from './LandingPagePromotionBanner'

interface LayoutInterface {
  data: any
}

const TemplateWrapper: FunctionComponent<LayoutInterface> = ({
  children,
  data,
}) => {
  return (
    <div>
      <Theme>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <NavigationLanding demoLink={data.demoLink} guide={data.guideLink} />
        <div>
          <GlobalStyles />
        </div>

        {children}

        <LandingPagePromotionBanner demoLink={data.demoLink} promoData={data} />
        <Footer />
      </Theme>
    </div>
  )
}

export default TemplateWrapper
