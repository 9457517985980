import React, { FunctionComponent } from 'react'
import MainGrid from '../../styling/MainGrid'
import Disclaimer from './IsolatedDisclaimer'
import LogoBanner from './IsolatedLogoBanner'
//import img from '../../../images/logo-banner/crown-commercial-supplier.png'
//import img2 from '../../../images/logo-banner/hm-treasury.png'
import img3 from '../../../images/logo-banner/NQA_ISO27001_BW.svg'
import img4 from '../../../images/logo-banner/NQA_ISO9001_BW.svg'
import img5 from '../../../images/logo-banner/global-cyber-alliance.png'

const logoBannerData = [
  {
    imgSrc: img3,
    alt: 'ISO27001',
  },
  {
    imgSrc: img4,
    alt: 'ISO9001',
  },
  {
    imgSrc: img5,
    alt: 'Global Cyber Alliance',
  },
]

const Footer: FunctionComponent = () => {
  return (
    <MainGrid>
      <LogoBanner data={logoBannerData} />
      <Disclaimer
        data={[
          {
            title: 'Terms',
            url: '/terms-and-conditions',
          },
          {
            title: 'Privacy',
            url: '/privacy-policy',
          },
        ]}
      />
    </MainGrid>
  )
}

export default Footer
