import React from 'react'
import { graphql, Link } from 'gatsby'
// import TrackVisibility from 'react-on-screen'
import LayoutLanding from '../components/LayoutLanding'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import Img from 'gatsby-image'
import CustomerTemplateLayout from '../components/CustomerStories/CustomerTemplateLayout'
import CustomerRightCol from '../components/CustomerStories/CustomerRightCol'
import ResourceLeftCol from '../components/ResourcesComponents/ResourceLeftCol'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'
import TrackVisibility from 'react-on-screen'
import PromotionBannerAnimation from '../components/PromotionBannerAnimation'
import { getResourceCardColor } from '../components/ResourcesComponents/ResourcesCard'
import styled from 'styled-components'
import Button from '../components/shared/Button/Button'
import { RichTextContainer } from '../components/RichTextContainer/RichTextContainer'
import { Helmet } from 'react-helmet'

interface Props {
  data: any
  pageContext: any
}
const resourceTemplate = ({ data, pageContext }: Props) => {
  const { wpPost } = data
  const breadcrumbData = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Resources',
      url: '/resources',
    },
    {
      title: wpPost.title,
      url: '/' + wpPost.slug,
    },
  ]
  // const { acfCourse2: courseData2 } = courseData
  let featImg = null
  try {
    featImg = wpPost.resources.featuredImage.mediaItemUrl
  } catch (e) {}

  const renderedBlocks = LazyBlocksFactory(
    wpPost.lazy_data,
    wpPost.title,
    featImg
  )
  function formatDate(date: string) {
    const month = date.substring(5, 7)
    let formatedMonth = ''
    switch (month) {
      case '01':
        formatedMonth = 'January'
        break
      case '02':
        formatedMonth = 'February'
        break
      case '03':
        formatedMonth = 'March'
        break
      case '04':
        formatedMonth = 'April'
        break
      case '05':
        formatedMonth = 'May'
        break
      case '06':
        formatedMonth = 'June'
        break
      case '07':
        formatedMonth = 'July'
        break
      case '08':
        formatedMonth = 'August'
        break
      case '09':
        formatedMonth = 'September'
        break
      case '10':
        formatedMonth = 'October'
        break
      case '11':
        formatedMonth = 'November'
        break
      case '12':
        formatedMonth = 'December'
        break
    }

    const formatedDay = date.substring(8, 10)
    const formatedYear = date.substring(0, 4)

    const formatedDate = formatedDay + ' ' + formatedMonth + ', ' + formatedYear
    return formatedDate
  }

  console.log(wpPost)

  return (
    <LayoutLanding data={wpPost.resources}>
      <MainGrid noPaddingMobile noPaddingTablet backgroundMobile="white">
        {/* <Breadcrumb data={breadcrumbData} /> */}
      </MainGrid>
      <CustomerTemplateLayout>
        {/* <CustomerLeftCol title={wpPost.title} /> */}
        {/* <ResourceLeftCol title={wpPost.resources.category} /> */}
        <CustomerRightCol>
          <Pill color={getResourceCardColor(wpPost.resources.category)}>
            {wpPost.resources.category}
          </Pill>

          <h1 style={{ marginBottom: 32 }}>{wpPost.title}</h1>
          {/* <b style={{ marginBottom: 67 }}>{formatDate(wpPost.date)}</b> */}

          {wpPost.resources.downloadLink ? (
            <DownloadLink
              href={wpPost.resources.downloadLink.mediaItemUrl}
              download
            >
              <Button>Download Assets</Button>
            </DownloadLink>
          ) : (
            ''
          )}
          {wpPost.resources.featuredImage && (
            <MainImage
              fluid={
                wpPost.resources.featuredImage.localFile.childImageSharp.fluid
              }
            />
          )}
          {/* <RichTextContainer richText={wpPost.lazy_data}/> */}
          {renderedBlocks.map((v) => v)}
          {wpPost.resources.downloadLink ? (
            <DownloadLink
              href={wpPost.resources.downloadLink.mediaItemUrl}
              download
            >
              <Button>Download Assets</Button>
            </DownloadLink>
          ) : (
            ''
          )}
          {/* <BackToResources to="/resources">Back to resources</BackToResources> */}
        </CustomerRightCol>
      </CustomerTemplateLayout>
    </LayoutLanding>
  )
}

export default resourceTemplate

export const pageQuery = graphql`
  query RescoureIsoById($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      slug
      lazy_data
      date
      title
      resources {
        fieldGroupName
        downloadLink {
          mediaItemUrl
        }
        category
        description
        isolatedForLandingPage
        demoLink
        promoBannerTitle
        promoBannerListItemOne
        promoBannerListItemTwo
        promoBannerListItemThree
        featuredImage {
          mediaItemUrl
          localFile {
            childImageSharp {
              fluid(maxWidth: 1080, toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const BackToResources = styled(Link)`
  font-weight: 900;
`

interface PillBackground {
  color: string
}
const Pill = styled.span<PillBackground>`
  background-color: ${(props) => props.color};
  padding: 3px 8px;
  border-radius: 9px;
  color: white;
  font-size: 9px;
  text-transform: uppercase;
  margin-right: 9px;
`
const Date = styled.span`
  font-size: 10px;
  font-weight: 700;
  color: #1d1e22;
`
const MainImage = styled(Img)`
  width: 100%;
  margin: 25px 0;
`
const DownloadLink = styled.a`
  max-width: 220px;
  margin: 37px 0 55px;
`
